import React from 'react';
import clsx from 'clsx';
import type { GetImageResult } from 'astro';
import type { HTMLAttributes } from 'react';
import { Spinner } from "@nextui-org/spinner";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from "@nextui-org/modal";
import Img from '@react/widget/Img';
import Icon from '@react/widget/Icon';

const playSizes = {
  "sm": "16",
  "md": "32"
};

interface Props extends HTMLAttributes<HTMLDivElement> {
  url?: string;
  playSize?: keyof typeof playSizes;
  image?: GetImageResult | Image;
  loading?: "lazy" | "eager";
}
const videoBtnBefore = "before:content-[''] before:absolute before:w-[160%] before:h-[160%] ";

const Youtube: React.FC<Props> = ({ className, url, playSize = "sm", image, loading = "lazy", children, ...props }) => {
  if (!url)
    return <></>;
  const youtube = /https:\/\/(?:www\.youtube\.com\/embed\/|www\.youtube\.com\/watch\?v=|youtu\.be\/)([\w-]+)/ig.exec(url);
  const YouTubeID = !!youtube ? youtube[1] : undefined;
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  return (
    <div className={clsx(className)} {...props}>
      <div className="absolute inset-0 flex items-end">
        {image && <Img className={"absolute inset-0 mx-auto rounded-full w-full h-full object-contain"} data={image} loading={loading} />}
        <div className="if-button flex m-auto mb-5 justify-center  lg:justify-end items-end w-full" data-url={`https://www.youtube.com/embed/${YouTubeID}`} aria-label="Play">
          <a onClick={onOpen} className={clsx(`cursor-pointer bg-white border rounded-full p-2 flex gap-4 max-w-[340px] lg:max-w-[70%] w-full lg:w-[378px] group shadow-md text-left items-center [&_p]:text-sm [&_p]:block z-10`)}>
            <div className="z-20 text-4xl text-white bg-green w-[90px] h-[90px] min-w-[90px] min-h-[90px] p-8 rounded-full flex justify-center items-center relative">
              <span className="animate-ping-slow group-hover:animate-none absolute inline-flex w-[58px] h-[58px] rounded-full bg-green opacity-100 -z-10"></span>
              <Icon name="play-sharp" />
            </div>
            <div className="pr-4">{children}</div>
          </a>
          <Modal
            classNames={{
              base: "rounded-lg overflow-hidden",
              body: "p-0 pb-[56.25%] relative bg-black-dark",
              closeButton: "z-10"
            }}
            size="5xl"
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            scrollBehavior={`inside`}
          >
            <ModalContent>
              <ModalBody>
                <Spinner className="absolute inset-0 m-auto" size="lg" color="white" />
                <iframe className="absolute inset-0" src={`https://www.youtube.com/embed/${YouTubeID}?autoplay=1`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" width="100%" height="100%" loading="lazy" />
              </ModalBody>
            </ModalContent>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Youtube;